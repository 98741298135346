import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './ARContestWinners.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

type WinnersType = {
  name: string;
  location: string;
};

export type Props = {
  id: string;
  backgroundImage?: types.ImageFields;
  headerImage?: types.ImageFields;
  winners: WinnersType[];
  innerText?: types.TextFields;
  innerMobileText?: types.TextFields;
  backgroundColor?: { color?: string };
  marginBottom?: number;
  marginBottomMobile?: number;
};

export const ARContestWinners = ({
  id,
  backgroundImage,
  headerImage,
  winners,
  innerText,
  innerMobileText,
  backgroundColor,
  marginBottom,
  marginBottomMobile,
}: Props) => {
  const [view, setView] = React.useState<types.Size>(types.Sizes.sm);

  const currentInnerText = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? innerText?.text ?? ''
        : innerMobileText?.text ?? '',
    [view, innerText, innerMobileText]
  );

  const handleViewMode = React.useCallback(() => {
    if (!window.innerWidth) return;

    setView(() => {
      return window.innerWidth >= types.Breakpoints.md
        ? types.Sizes.lg
        : types.Sizes.sm;
    });
  }, []);

  React.useEffect(() => {
    if (!window) return;

    handleViewMode();
    window.addEventListener('resize', handleViewMode);
    return () => window.removeEventListener('resize', handleViewMode);
  }, [handleViewMode]);

  return (
    <section
      id={id}
      style={
        {
          '--background-color': backgroundColor?.color,
        } as React.CSSProperties
      }
      className={clsx(scss.wrapper, 'ar-contest-winners')}>
      {backgroundImage && (
        <img
          className={clsx(scss.backgroundImage)}
          src={getStrapiImageSource(backgroundImage.src[view])}
          alt={backgroundImage.alt}
        />
      )}

      {headerImage && (
        <img
          className={clsx(
            scss.headerLogoImage,
            !winners.length && scss.noMargin
          )}
          style={
            {
              '--margin-bottom':
                marginBottom == null ? null : `${marginBottom}%`,
              '--margin-bottom-mobile':
                marginBottomMobile == null ? null : `${marginBottomMobile}%`,
            } as React.CSSProperties
          }
          src={getStrapiImageSource(headerImage.src[view])}
          alt={headerImage.alt}
        />
      )}

      {winners.length ? (
        <div className={clsx(scss.inner)}>
          <div className={clsx(scss.innerWinnersContainer)}>
            <div className={clsx(scss.innerWinners)}>
              {winners.map((winner, index) => (
                <div
                  key={`winner-${index}`}
                  className={clsx(scss.innerWinnersWinner)}>
                  <span className={clsx(scss.innerWinnersName)}>
                    {winner.name}
                  </span>
                  <span className={clsx(scss.innerWinnersLocation)}>
                    {winner.location}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <p
            className={clsx(scss.innerText)}
            dangerouslySetInnerHTML={{ __html: currentInnerText }}
          />
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};
